
import { Options, Vue } from "vue-class-component";
import axios from "@/plugins/axios";

interface Result {
  data: any;
  message: string;
  name: string;
  type: string;
  status: boolean;
}
@Options({
  props: {
    listData: [],
    applicationIds:{},
    callbacks:{}
  },
})
export default class SettingPage extends Vue {
  headers = {
    "token-user": localStorage.getItem("tokenUser"),
    account: localStorage.getItem("account"),
  };
  url = `${process.env.VUE_APP_BASE_URL}/`;
  //
  listData: any = [];
  //
  dataFrom: any = {};
  //
  options: any = [];
  //
  titleFromPlatForm = "";
  //
  platformType = "";
  //
  applicationIds: any={}
  callbacks: any={}
  //
  newConnectId: any = ''
  dialogFromPlatform = false;
  visible = false

 async created(){
    if (this.$route.query.isError && this.$route.query.isError !== "false") {
        await this.$message.warning("Update token fail, Please contact us for instructions!!!");
          this.handleDeleteConnect(this.$route.query.connectionId)
          this.$router.replace({ query: {} });
    } 
     if (this.$route.query.isError && this.$route.query.isError === "false" && this.$route.query.message) {
   await this.$message.success(this.$route.query.message);
       this.$emit("finishHandle", this.$route.query.type)
   await this.$router.replace({ query: {} });
    }
  }
  selectFromPlatform(platform: any) {
    this.titleFromPlatForm = `${platform.name} connection.`;
    this.options = platform.options;
    for (let item of this.options) {
      this.dataFrom[item.key] = "";
    }
    this.dataFrom.name = "";
    this.dataFrom.type = platform.type;
    this.dataFrom.platform = platform._id;
    this.dialogFromPlatform = true;
  }
  async saveFromConnect() {
    await this.$store.dispatch("setLoading", true, { root: true });
    const refFrorm = this?.$refs["refForm"] as HTMLFormElement;
    const isValidate = await refFrorm.validate();
    if (!isValidate) return;
    this.dataFrom.connectFrom = true;
    this.dataFrom.connectTo = false;
    let res: Result;
    res = await axios.post("user/connect", this.dataFrom, {
      headers: this.headers,
    });

    if (res.data) {
      this.dialogFromPlatform = false;
      this.platformType = this.dataFrom.type;
      this.newConnectId = res.data._id
      if (['shopify', 'channelAdvisor', 'quickbook', 'xero'].includes(res.data.type)) {
        this.handleUpdateToken(res.data);
        return;
      } else {
        this.$message.success(res.message);
        this.$emit("finishHandle", this.platformType, res.data)
      }
    } else {
      this.$message.warning(res.message);
    }
    await this.$store.dispatch("setLoading", false, { root: true });
  }
  goBack(){
     this.$emit("goBack")
  }
  async handleDeleteConnect (id: any) {
    await axios.delete('user/connect/delete-fail-add-token', {
        params: { id: id },
        headers: this.headers,
    }).then(function (response: any) {
        console.log(response.message);
    }, function (response: any) {
        console.log(response.message);
    });
  }

  async handleUpdateToken(connection: any) {    
    await this.$store.dispatch("setLoading", true, { root: true });
    let query : any
    let urlPath: any = null
    if (connection.type == 'shopify') {
        query = this.encodeQueryData({
            client_id: this.applicationIds.shopify,
            scope: 'write_payment_terms,read_publications,write_publications,read_products,write_products,read_orders,write_orders,read_all_orders,read_inventory,write_inventory,read_draft_orders,write_draft_orders,read_merchant_managed_fulfillment_orders,write_merchant_managed_fulfillment_orders,read_assigned_fulfillment_orders,write_assigned_fulfillment_orders,read_third_party_fulfillment_orders,write_third_party_fulfillment_orders,read_shipping,write_shipping,read_fulfillments,write_fulfillments,read_returns,write_returns',
            redirect_uri: this.callbacks.shopify,
            state: JSON.stringify({
                'tokenUser': localStorage.getItem('tokenUser'),
                'accountId': localStorage.getItem('account'),
                'connectionId': connection._id,
                'currentPage': window.location.href,
            }),
        });
        urlPath = 'https://' + connection.shopify_shopName + '.myshopify.com/admin/oauth/authorize?' + query
    }
    if (connection.type == 'channelAdvisor') {
        query = this.encodeQueryData({
            client_id: this.applicationIds.channelAdvisor,
            response_type: 'code',
            access_type: 'offline',
            scope: 'orders inventory',
            approval_prompt: connection?.tokenObject?.hasRefreshToken ? 'auto' : 'force',
            redirect_uri: this.callbacks.channelAdvisor,
            state: JSON.stringify({
                    'tokenUser': localStorage.getItem('tokenUser'),
                    'accountId': localStorage.getItem('account'),
                    'connectionId': connection._id,
                    'currentPage': window.location.href,
            }),
        });
        urlPath = 'https://api.channeladvisor.com/oauth2/authorize?' + query
    }
    if (connection.type == "xero") {
      query = this.encodeQueryData({
        response_type: 'code',
        //client_id: '38B4D13E09A441F79F5419B1BC0EDFC2',
        redirect_uri: this.callbacks.xero,
        client_id: this.dataFrom.xero_client_id,
        //redirect_uri: 'https://dev.iomnic.com',
        scope: 'accounting.transactions offline_access accounting.contacts accounting.settings',
        state: JSON.stringify({
                  'tokenUser': localStorage.getItem('tokenUser'),
                  'accountId': localStorage.getItem('account'),
                  'connectionId': connection._id,
                  'currentPage': window.location.href,
        }),
      });
      urlPath = "https://login.xero.com/identity/connect/authorize?" + query;
    }
    if (connection.type === 'esty') {
      query = this.encodeQueryData({
        client_id: this.applicationIds.esty.user,
        response_type: 'code',
        code_challenge: this.applicationIds.esty.codeChallenge,
        code_challenge_method: this.applicationIds.esty.codeChallengeMethod,
        scope: this.applicationIds.esty.scopes.join(' '),
          
        redirect_uri: this.callbacks.esty,
        state: JSON.stringify({
          tokenUser: localStorage.getItem("tokenUser"),
          accountId: localStorage.getItem("account"),
          connectionId: connection._id,
          currentPage: window.location.href,
          codeVerifier: this.applicationIds.esty.codeVerifier
        }),
      });
      urlPath = "https://www.etsy.com/oauth/connect?" + query;
    }
    if (connection.type === "quickbook") {
      urlPath = this.applicationIds.quickbook.urlAuth;
      if (urlPath) {
        urlPath = urlPath
          .replace('tokenUser_iomnic', encodeURIComponent(localStorage.getItem("tokenUser") || ''))
          .replace('accountId_iomnic', localStorage.getItem("account"))
          .replace('connectionId_iomnic', connection._id)
          .replace('currentPage_iomnic', encodeURIComponent(window.location.href))
      }
    }
    if (urlPath != null) {
    window.open(urlPath, '_self', '');
    window.close();
      // this.$nextTick( async () => {

      //   let oauthState: any = await this.showAuthWindow({
      //       path: urlPath
      //   })
        
      //   if (Object.keys(oauthState).length && oauthState.isError === false) {
      //     this.$message.success(oauthState.message);
      //     this.$emit("finishHandle", connection.type, connection)
      //     await this.$store.dispatch("setLoading", false, { root: true });
      //   } else {
      //     this.$message.warning("Update token fail, Please contact us for instructions!!!");
      //     this.handleDeleteConnect(connection._id)
      //     await this.$store.dispatch("setLoading", false, { root: true });
      //   }
      // })
    }
  }

  showAuthWindow(options: { path: any; windowName?: any; windowOptions?: any; }) {   
    return new Promise((resolve, reject) => {
      options.windowName = options.windowName || 'ConnectWithOAuth';
      options.windowOptions = options.windowOptions || 'location=0,status=0,width=800,height=600';
      var _oauthWindow: any = open(options.path, options.windowName, options.windowOptions);
      _oauthWindow.focus();
      var oauthState = {};

      function onPopupSendMessage(event: any) {
          if (event.data.event === 'oauthState') {
              oauthState = event.data;  
          }
      };

      window.addEventListener('message', onPopupSendMessage);

      var _oauthInterval = setInterval( () => {
        if (!_oauthWindow.closed) return;
        clearInterval(_oauthInterval);
        resolve(oauthState)
        window.removeEventListener('message', onPopupSendMessage);
      }, 1000);
    })
  }
  encodeQueryData(data: any) {
      let ret = [];
      for (let d in data)
          ret.push(encodeURIComponent(d) + '=' + encodeURIComponent(data[d]));
      return ret.join('&');
  }
}


import ShipmentMapping from '../components/ShipmentMapping.vue'
import { Options, Vue } from "vue-class-component";
import axios from "@/plugins/axios";
import { ElMessageBox } from "element-plus";
import MappingPage from "../components/Mapping.vue";
interface Res {
  list: [];
  total: number;
}
@Options({
  components: {
    ShipmentMapping,
  },
})
export default class ConnectionPage extends Vue {
  titlePage = "Connection";
  titleModalConnect = "";
  headers = {
    "token-user": localStorage.getItem("tokenUser"),
    account: localStorage.getItem("account"),
  };
  url = `${process.env.VUE_APP_BASE_URL}/`;
  connectData: any = [];
  platformData: any = [];
  dataConnect: any = {};
  applicationIds: any = {};
  callbacks: any = {};
  listCronDelete = [{}];
  listConnectionExpiryDate = [{ _id: "" }];
  listScript = [
    {
      connectFrom: { _id: "" },
      connectTo: { _id: "" },
      expiried: false,
      status: false,
    },
  ];
  dialogAddConnect = false;
  connectFrom = "";
  disabledSelect = false;
  options = [];
  idConnect = "";
  typeMapping = "";
  isMapping = false;
  eventUnregisterers: any = [];
  objDiv: any;
  created() {
    this.getListConnect();
    this.getListScript();
    this.getListPlatform();
  }
  async getListConnect() {
    await this.$store.dispatch("setLoading", true, { root: true });
    let res: any = {};
    res = await axios.get("user/connect/list", {
      headers: this.headers,
    });
    if (res.data) {
      this.connectData = res.data;
      for (let connect of this.connectData) {
        if (connect.connectFrom) {
          this.connectFrom = connect.type;
        }
      }
      this.applicationIds = res.applicationIds;
      this.callbacks = res.callbacks;
    }
    await this.$store.dispatch("setLoading", false, { root: true });
  }
  async getListScript(page = 0) {
    let res: Res = {
      list: [],
      total: 0,
    };
    await this.$store.dispatch("setLoading", true, { root: true });
    res = await axios.get("user/setting-script", {
      headers: this.headers,
      params: {
        account: localStorage.getItem("account"),
        page: page,
        limit: 100,
      },
    });
    if (res) {
      this.listScript = res.list;
    }
    await this.$store.dispatch("setLoading", false, { root: true });
  }
  async getListPlatform() {
    let res: any = {};
    res = await axios.get("admin/platform", {
      headers: this.headers,
    });
    if (res) {
      this.platformData = res.data;
    }
  }

  handleOpenConnect(connect: any) {
    this.dataConnect = {};
    if (connect) {
      this.titleModalConnect = `${connect.name} connection`;
      let data = this.platformData.filter(
        (platform: any = {}) => platform._id == connect.platform._id
      );
      this.options = data[0].options;
      for (let item of data[0].options) {
        this.dataConnect[item.key] = connect[item.key];
      }
      this.dataConnect.name = connect.name;
      this.dataConnect.type = connect.type;
      this.dataConnect.id = connect._id;
      this.dataConnect.connectFrom = connect.connectFrom;
      this.dataConnect.connectTo = connect.connectTo;
      this.disabledSelect = true;
    } else {
      this.titleModalConnect = `Add new connection`;
      this.dataConnect.connectFrom = false;
      this.dataConnect.connectTo = true;
      this.disabledSelect = false;
    }
    this.dialogAddConnect = true;
  }
  handleOption() {
    for (let platform of this.platformData) {
      if (platform.type === this.dataConnect.type) {
        this.dataConnect.platform = platform._id;
        this.dataConnect.price = platform.price;
        this.dataConnect.discount = platform.discount;
        this.options = platform.options;
        for (let item of platform.options) {
          this.dataConnect[item.key] = "";
        }
      }
    }
  }
  async handleSave() {
    const refFrorm = this?.$refs["refForm"] as HTMLFormElement;
    const isValidate = await refFrorm.validate();
    if (!isValidate) return;
    await this.$store.dispatch("setLoading", true, { root: true });
    console.log(this.dataConnect);

    let res: any;
    if (this.dataConnect.id) {
      res = await axios.put("user/connect", this.dataConnect, {
        headers: this.headers,
      });
    } else {
      res = await axios.post("user/connect", this.dataConnect, {
        headers: this.headers,
      });
    }

    if (res.status) {
      this.$message.success(res.message);
      this.dialogAddConnect = false;
      this.getListConnect();
    } else {
      this.$message.warning(res.message);
    }

    await this.$store.dispatch("setLoading", false, { root: true });
  }
  async handleDelete(id: any) {
    let res: any;
    ElMessageBox.confirm("Do you want to delete?", "Warning", {
      confirmButtonText: "OK",
      cancelButtonText: "Cancel",
      type: "warning",
    }).then(async () => {
      this.listScript.forEach((cron) => {
        if (id === cron.connectFrom._id || id === cron.connectTo._id) {
          this.listCronDelete.push(cron);
        }
      });
      this.listCronDelete.shift();
      if (this.listCronDelete.length > 0) {
        await axios.put(
          "user/setting-script/delete-many",
          this.listCronDelete,
          {
            headers: this.headers,
            params: {
              account: localStorage.getItem("account"),
            },
          }
        );
      }
      res = await axios.delete("/user/connect", {
        params: { id: id },
        headers: this.headers,
      });
      if (res.status) {
        this.$message.success(res.message);
        this.listCronDelete = [{}];
        this.getListConnect();
      } else {
        this.$message.warning(res.message);
      }
    });
  }
  handleMapping(id: string, type: string) {
    this.isMapping = false;
    if (id && type) {
      this.idConnect = id;
      this.typeMapping = type;
      this.isMapping = true;
    }
  }
  closeModalMapping() {
    this.isMapping = false;
  }

  async handleUpdateToken(connection: any) {
    await this.$store.dispatch("setLoading", true, { root: true });
    let query: any;
    let urlPath: any = null;
    if (connection.type == "shopify") {
      query = this.encodeQueryData({
        client_id: this.applicationIds.shopify,
        scope:
          "write_payment_terms,read_publications,write_publications,read_products,write_products,read_orders,write_orders,read_all_orders,read_inventory,write_inventory,read_draft_orders,write_draft_orders,read_merchant_managed_fulfillment_orders,write_merchant_managed_fulfillment_orders,read_assigned_fulfillment_orders,write_assigned_fulfillment_orders,read_third_party_fulfillment_orders,write_third_party_fulfillment_orders,read_shipping,write_shipping,read_fulfillments,write_fulfillments,read_returns,write_returns",
        redirect_uri: this.callbacks.shopify,
        state: JSON.stringify({
          tokenUser: localStorage.getItem("tokenUser"),
          accountId: localStorage.getItem("account"),
          connectionId: connection._id,
        }),
      });
      urlPath =
        "https://" +
        connection.shopify_shopName +
        ".myshopify.com/admin/oauth/authorize?" +
        query;
    }
    if (connection.type == "channelAdvisor") {
      query = this.encodeQueryData({
        client_id: this.applicationIds.channelAdvisor,
        response_type: "code",
        access_type: "offline",
        scope: "orders inventory",
        approval_prompt: connection.tokenObject.hasRefreshToken
          ? "auto"
          : "force",
        redirect_uri: this.callbacks.channelAdvisor,
        state: JSON.stringify({
          tokenUser: localStorage.getItem("tokenUser"),
          accountId: localStorage.getItem("account"),
          connectionId: connection._id,
        }),
      });
      urlPath = "https://api.channeladvisor.com/oauth2/authorize?" + query;
    }
    if (connection.type === 'esty') {
      query = this.encodeQueryData({
        client_id: this.applicationIds.esty.user,
        response_type: 'code',
        code_challenge: this.applicationIds.esty.codeChallenge,
        code_challenge_method: this.applicationIds.esty.codeChallengeMethod,
        scope: this.applicationIds.esty.scopes.join(' '),
          
        redirect_uri: this.callbacks.esty,
        state: JSON.stringify({
          tokenUser: localStorage.getItem("tokenUser"),
          accountId: localStorage.getItem("account"),
          connectionId: connection._id,
          codeVerifier: this.applicationIds.esty.codeVerifier
        }),
      });
      urlPath = "https://www.etsy.com/oauth/connect?" + query;
    }
    if (connection.type === "quickbook") {
      urlPath = this.applicationIds.quickbook.urlAuth;
      if (urlPath) {
        urlPath = urlPath
          .replace('tokenUser_iomnic', encodeURIComponent(localStorage.getItem("tokenUser") || ''))
          .replace('accountId_iomnic', localStorage.getItem("account"))
          .replace('connectionId_iomnic', connection._id)
          .replace('currentPage_iomnic', encodeURIComponent(window.location.href))
      }
    }
    if (connection.type === "tiktok") {
      query = this.encodeQueryData({
        app_key: this.applicationIds.tiktok,
        state: JSON.stringify({
          tokenUser: localStorage.getItem("tokenUser"),
          accountId: localStorage.getItem("account"),
          connectionId: connection._id,
          currentPage: window.location.href,
        }),
      });
      urlPath = "https://auth.tiktok-shops.com/oauth/auhorize?" + query;
    }
    if (connection.type === 'brightpearl') {
      query = this.encodeQueryData({
        response_type: 'code',
        client_id: this.applicationIds.brightpearl.clientId,
        redirect_uri: this.applicationIds.brightpearl.redirectUri,
        state: JSON.stringify({
          tokenUser: localStorage.getItem("tokenUser"),
          accountId: localStorage.getItem("account"),
          connectionId: connection._id,
          currentPage: window.location.href,
        }),
      });
      urlPath = `https://oauth.brightpearl.com/authorize/${connection.brightpearlAccountId}?` + query;
    }
    this.$nextTick(async () => {
      let oauthState: any = await this.showAuthWindow({
        path: urlPath,
      });
      this.$store.dispatch("setLoading", false, { root: true });
      if (oauthState) {
        this.$message.success(oauthState.message);
      } else {
        this.$message.warning("Update token fail !!!");
      }
    });
  }

  showAuthWindow(options: {
    path: any;
    windowName?: any;
    windowOptions?: any;
  }) {
    return new Promise((resolve, reject) => {
      options.windowName = options.windowName || "ConnectWithOAuth";
      options.windowOptions =
        options.windowOptions || "location=0,status=0,width=800,height=600";
      // options.callback = options.callback || function () { window.location.reload() };
      var _oauthWindow: any = open(
        options.path,
        options.windowName,
        options.windowOptions
      );
      _oauthWindow.focus();
      var oauthState = {};

      function onPopupSendMessage(event: any) {
        if (event.data.event === "oauthState") {
          oauthState = event.data;
        }
      }

      window.addEventListener("message", onPopupSendMessage);

      var _oauthInterval = setInterval(function () {
        if (!_oauthWindow.closed) return;
        clearInterval(_oauthInterval);
        //options.callback(oauthState);
        resolve(oauthState);
        window.removeEventListener("message", onPopupSendMessage);
      }, 1000);
    });
  }
  encodeQueryData(data: any) {
    let ret = [];
    for (let d in data)
      ret.push(encodeURIComponent(d) + "=" + encodeURIComponent(data[d]));
    return ret.join("&");
  }
  selectToPlatform(platform: any) {
    this.dataConnect = {};
    this.titleModalConnect = `${platform.name} connection`;

    this.options = platform.options;
    for (let item of platform.options) {
      this.dataConnect[item.key] = platform[item.key];
    }
    this.dataConnect.type = platform.type;
    this.dataConnect.platform = platform._id;
    this.dataConnect.connectFrom = false;
    this.dataConnect.connectTo = true;
    this.dialogAddConnect = true;
  }
    truncate(string: any) {
    if (string.length > 20) return string.substring(0, 20) + "...";
    else return string;
  }
}


import { Options, Vue } from 'vue-class-component'
import { ElMessageBox } from 'element-plus'
import axios from '@/plugins/axios'
import Message from 'element-plus'

interface Item {
  _id: string
}
interface Result {
  message: string
}

@Options({
  props: {
    listData: []
  }
})
export default class ProductTable extends Vue {
  name = 'Product Table'
  headers = {
    'token-user': localStorage.getItem('tokenUser'),
    account: localStorage.getItem('account'),
    connectionTo: null
  }
  dialogProductDetail = false
  defaultAttributeSelected = [
    'sku',
    'quantity',
    'title',
    'price',
    'isParent',
    'timeStamp',
    'status'
  ]
  listData: any
  listAttributes: any = []
  multipleSelection = []
  listConection: any = []
  dataProductDetail: any = {
    _id: null,
    isParent: true,
    parentSku: ''
  }
  titleModal = ''
  showFilter = false
  connectionTarget: any = []
  condition: any = {}
  targetSelected: any = null
  labelFrom: any = ''
  labelTo: any = ''
  listStatus = [
    { label: 'All', value: undefined },
    { label: 'Completed', value: true },
    { label: 'Failed', value: false },
    { label: 'In progress', value: 'inProgress' }
  ]

  daterange = []
  exportLoading = false
  async created() {
    this.$store.commit('setTargetConnection', this.targetSelected)
    await Promise.all([this.getListConnectTarget()])
    await this.getListAttribute()
    if (this.connectionTarget.length) {
      const sku = this.$route.query.sku
      if (sku) {
        this.condition.sku = sku
        this.getSearchData()
        this.$watch(
          'listData',
          (newVal: any) => {
            if (newVal.length && this.$route.query.sku) {
              this.$router.push('/products')
              this.handleOpenProductDetail(newVal[0])
            }
          },
          { immediate: true, deep: true }
        )
      } else {
        this.getSearchData()
      }
      this.headers.connectionTo = this.targetSelected
    }    
  }
  handleOpenProductDetail(row: any) {
    this.dialogProductDetail = true
    let attr: any

    if (row) {
      this.titleModal = 'Product Details'
      for (attr of this.listAttributes) {
        let keyWord = attr.name
        this.dataProductDetail[keyWord] = row[keyWord] || ''
      }
      this.dataProductDetail._id = row._id
      this.dataProductDetail[this.labelTo] = row[this.labelTo] || row['target_id']
      this.dataProductDetail[this.labelFrom] = row[this.labelFrom] || row['hub_id']
      if (this.labelFrom === this.labelTo) {
        this.dataProductDetail['hub_id'] = row[this.labelFrom] || row['hub_id']
        this.dataProductDetail['target_id'] = row[this.labelTo] || row['target_id']
      }
      this.dataProductDetail.isParent = row.isParent
      this.dataProductDetail.skuParent = row.skuParent
      this.dataProductDetail.dateUpdated = row.dateUpdated
      this.dataProductDetail.history = row.history || []
    } else {
      this.titleModal = 'Add Product'
      for (attr of this.listAttributes) {
        let keyWord = attr.name
        this.dataProductDetail[keyWord] = ''
      }
    }
  }

  handleSelectionChange(val: any) {
    this.multipleSelection = val
  }
  toggleSelection() {
    const refFrorm = this?.$refs.multipleTable as HTMLFormElement
    refFrorm.clearSelection()
  }

  async exportProducts() {
    ElMessageBox.confirm(
      'Do you confirm to export all selected. Continue?',
      'Warning',
      {
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        type: 'success',
      }
    ).then(async () => {
      this.exportLoading = true
      this.headers.connectionTo = this.$store.state['targetConnection']
      let listSkuExport:any = []
      listSkuExport = this.multipleSelection.map((item:any) => {
        return item.sku
      })

      let condition: any = listSkuExport.length 
        ? { "sku": { "$in": listSkuExport } }
        : {}
      
      if (this.daterange.length) {
        condition.dateUpdated = {
          $gte: new Date(this.daterange[0]),
          $lte: new Date(this.daterange[1])
        }
      }

      let res = await axios
        .post('user/product/export', {
          headers: this.headers,
          condition: JSON.stringify(condition)
        })
        .catch((err) => console.log('[ERROR]', err))
      if (res) {
        const linkFileExport = (await res.data.linkFileExport) || ''
        if (
          linkFileExport != undefined ||
          linkFileExport != null ||
          linkFileExport != ''
        ) {
          ElMessageBox.alert(
            'The export file will be send to the email addresses in Notifications section. Please check your mailbox after few minutes.',
            'Notification',
            {
              confirmButtonText: 'OK',
              type: 'success',
              center: true
            }
          )
          setTimeout(() => {
            // window.open(linkFileExport)
            this.exportLoading = false
          }, 10000)
        } else {
          ElMessageBox.alert('Error!!', 'Notification', {
            confirmButtonText: 'Close',
            type: 'warning',
            center: true
          })
        }
      }
    })
    .catch(() => console.log('null'))
  }

  async getListAttribute() {
    let res = await axios.get('user/product/attribute', {
      headers: this.headers
    })

    if (res) {
      this.listAttributes = await res.data.attribute
    
      let attibuteConnection = this.connectionTarget.find(
        (connect: any) => connect._id == this.targetSelected
      )
      let deletedMappingDefault = attibuteConnection.deletedMappingDefault || [];
      this.listAttributes = this.listAttributes.filter((item1:any) => !deletedMappingDefault.some((item2:any) => item1.name === item2.original));

      for (let attr of attibuteConnection['mapping']) {
        let dataAtt: any = {
          name: attr.saas,
          type: 'string'
        }
        this.listAttributes.push(dataAtt)
      }
      this.listAttributes.push(
        {
          name: 'isParent',
          type: 'string'
        },
        {
          name: 'timeStamp',
          type: 'string'
        },
        {
          name: 'status',
          type: 'string'
        }
      )
      if (this.labelFrom !== this.labelTo) {
        this.listAttributes.unshift(
          {
            name: this.labelFrom,
            type: 'string'
          },
          {
            name: this.labelTo,
            type: 'string'
          }
        )
      } else {
        this.listAttributes.unshift(
          {
            name: 'hub_id',
            type: 'string'
          },
          {
            name: 'target_id',
            type: 'string'
          }
        )
      }
    }
  }

  async handleDelete() {
    if (this.multipleSelection.length) {
      ElMessageBox.confirm(
        'Do you want to delete seleted product?',
        'Warning',
        {
          confirmButtonText: 'OK',
          cancelButtonText: 'Cancel',
          type: 'warning'
        }
      ).then(async () => {
        var params
        var ids = []
        let item: Item
        for (item of this.multipleSelection) {
          ids.push(item._id)
          params = { idList: ids }
        }
        let res: Result
        res = await axios.delete('user/product', {
          params: params,
          headers: this.headers
        })
        if (res) {
          this.$message.success(res.message)
          this.$emit('finishHandle')
        }
      })
    } else {
      this.$message.warning('There are not product selected !!!')
    }
  }
  async handleSaveProduct() {
    let res: Result
    if (
      this.dataProductDetail.sku === '' &&
      this.dataProductDetail.sku != 'string'
    ) {
      this.$message.warning(
        'sku is required to create product',
        'Invalid form!'
      )
    }

    if (this.dataProductDetail._id) {
      res = await axios.put('user/product', this.dataProductDetail, {
        headers: this.headers
      })
    } else {
      res = await axios.post('user/product', this.dataProductDetail, {
        headers: this.headers
      })
    }

    let msg = res.message
    if (res) {
      this.$message.success(msg)
      this.dialogProductDetail = false
      this.$emit('finishHandle')
    } else {
      this.$message.warning(msg)
      this.dialogProductDetail = false
    }
  }
  async getListConnectTarget() {
    let res: any = {}
    res = await axios.get('user/connect/list', {
      headers: this.headers
    })
    if (res.data) {
      for (let connect of res.data) {
        this.listConection = res.data;        
        if (connect.connectFrom) {
          this.labelFrom = connect['type'].toLowerCase() + '_id'
        }
        if (connect.connectTo) {
          this.connectionTarget.push(connect)
        }
      }

      if (this.connectionTarget.length) {
        this.targetSelected = this.connectionTarget[0]._id
        this.labelTo = this.connectionTarget[0].type.toLowerCase() + '_id'
      }
    }
  }
  async getSearchData() {
    this.$store.commit('setTargetConnection', this.targetSelected)
    let newData: any = {}
    for (const item in this.condition) {
      if (item === 'completed' && this.condition[item] !== undefined) {
        if (this.condition[item] === 'inProgress') {
          newData['inProgress'] = true
        } else {
          newData[item] = this.condition[item]
          newData['inProgress'] = false
        }
      } else if (
        typeof this.condition[item] !== 'undefined' &&
        this.condition[item] !== ''
      ) {
        newData[item] = { $regex: this.condition[item], $options: 'i' }
      }
    }
    this.connectionTarget.find((connect: any) => {
      if (connect._id === this.targetSelected) {
        this.labelTo = connect['type'].toLowerCase() + '_id'
      }
    })

    if (this.daterange.length) {
      newData.dateUpdated = {
        $gte: new Date(this.daterange[0]),
        $lte: new Date(this.daterange[1])
      }
    }

    let data = {
      condition: newData,
      target: this.targetSelected
    }
    this.getListAttribute()
    this.defaultAttributeSelected = [
      'title',
      'sku',
      'price',
      'quantity',
      'timeStamp',
      'status'
    ]
    // this.defaultAttributeSelected.unshift(this.labelFrom, this.labelTo);
    this.$emit('search', data)
  }
  handleClear() {
    this.condition.title = ''
    this.condition.sku = ''
    this.condition.completed = undefined
    this.condition.dateUpdated = undefined
    this.daterange = []
    this.getSearchData()
  }
  trimmedString(string: string, length: number) {
    return string.length > length ? string.substring(0, length) + '...' : string
  }
  goToCronPage() {
    this.$router.push({ name: 'CronPage' })
  }
  goToAttributeMappingPage() {
    this.$router.push({
      name: 'AttributePage',
      params: { id: this.targetSelected }
    })
  }
  customHistory(history:any) {
    let output = ''
    for (let item of (history || [])) {
      output += `\nTime: ${item.time}\nContent: ${item.error}\n\n----------------------------------------------------------------------------------------------\n`
    }

    return output.replace('\n', '')
  }
}


import { Options, Vue } from 'vue-class-component'
import { PrismEditor } from 'vue-prism-editor'
import 'vue-prism-editor/dist/prismeditor.min.css'
import { highlight, languages } from 'prismjs'
import 'vue-prism-editor/dist/prismeditor.min.css'
import 'prismjs/components/prism-clike'
import 'prismjs/components/prism-javascript'
import 'prismjs/themes/prism-tomorrow.css'
import axios from '@/plugins/axios'
import { ElMessage } from 'element-plus'
import { ElMessageBox } from 'element-plus'

interface Res {
  list: []
  listTitle: []
  total: number
}
interface Result {
  total: number
  data: []
}
@Options({
  components: { PrismEditor }
})
export default class TablePage extends Vue {
  headers = {
    'token-user': localStorage.getItem('tokenUser'),
    account: localStorage.getItem('account')
  }
  listScript = [
    {
      account: '',
      title: '',
      type: '',
      connectionFromType: '',
      connectionToType: '',
      content: ''
    }
  ]
  listFrom = [
    {
      label: 'BigCommerce',
      value: 'bigcommerce'
    },
    {
      label: 'ChannelAdvisor',
      value: 'channeladvisor'
    },
    {
      label: 'Linnworks',
      value: 'linnworks'
    },
    {
      label: 'Magento 2',
      value: 'magento2'
    },
    {
      label: 'Shopify',
      value: 'shopify'
    },
    {
      label: 'Woocommerce',
      value: 'woocommerce'
    },
    {
      label: 'Etsy',
      value: 'esty'
    },
    {
      label: 'HyperMule',
      value: 'hypermule'
    },
    {
      label: 'Peoplevox',
      value: 'peoplevox'
    },
    {
      label: 'Tiktok',
      value: 'tiktok'
    },
    {
      label: 'Alphabroder',
      value: 'alphabroder'
    },
    {
      label: 'QuickBooks',
      value: 'quickbook'
    },
    {
      label: 'QuickBookDesktop',
      value: 'quickbookdesktop'
    },
    {
      label: 'Prestashop',
      value: 'prestashop'
    },
    {
      label: 'VisualSoft',
      value: 'visualsoft'
    },
    {
      label: 'Lightspeed',
      value: 'lightspeed'
    },
    {
      label: 'Brightpearl',
      value: 'brightpearl'
    },
    {
      label: 'Essendant',
      value: 'essendant'
    },
    {
      label: 'Odoo',
      value: 'odoo'
    },
    {
      label: 'Xentral',
      value: 'xentral'
    },
    {
      label: 'NetSuite',
      value: 'netsuite'
    },
    {
      label: 'LightSpeedXSeries',
      value: 'lightspeedx'
    },
    {
      label: 'Xero',
      value: 'xero'
    },
    {
      label: 'Apprise',
      value: 'apprise'
    },
    {
      label: 'Portica',
      value: 'portica'
    },
    {
      label: 'ActiveAnt',
      value: 'activeant'
    },
    {
      label: 'Mebel',
      value: 'mebel'
    },
    {
      label: 'Aims360',
      value: 'aims360'
    },
    {
      label: 'Cin7',
      value: 'cin7'
    },
    {
      label: 'SapB1',
      value: 'sapb1'
    },
    {
      label: 'Dynamics365',
      value: 'dynamics365'
    },
    {
      label: "Teapplix",
      value: "teapplix",
    },
    {
      label: "Convictional",
      value: "convictional",
    },
    {
      label: "Warner",
      value: "warner",
    },

    {
      label: 'CommerceHub',
      value: 'commercehub'
    },
    {
      label: "MyFLEX",
      value: "myflex",
    },
    {
      label: "Dynamics AX EDI",
      value: "dynamicsax",
    },
    {
      label: "Dynamics F&O",
      value: "dynamicsfo",
    },
    {
      label: "SapB1FileExport",
      value: "sapb1fileexport",
    },
    {
      label: 'ChannelAdvisorFileImport',
      value: 'channeladvisorfileimport'
    },
  ]
  listTo = [...this.listFrom]
  listFunctionProduct = [
  {
      value: 'getDataConnection',
      label: 'getDataConnection'
    },
    {
      value: 'getListProductHub',
      label: 'getListProductHub'
    },
    {
      value: 'importProductHubToIO',
      label: 'importProductHubToIO'
    },
    {
      value: 'mappingProductHubToIO',
      label: 'mappingProductHubToIO'
    },
    {
      value: 'getListIOToTarget',
      label: 'getListIOToTarget'
    },
    {
      value: 'getListProductIO',
      label: 'getListProductIO'
    },
    {
      value: 'importProductIOToTarget',
      label: 'importProductIOToTarget'
    },
    {
      value: 'mappingProductIOToTarget',
      label: 'mappingProductIOToTarget'
    },
    {
      value: 'mappingProductIOToImportFileTarget',
      label: 'mappingProductIOToImportFileTarget'
    },
    {
      value: 'importProductFileTarget',
      label: 'importProductFileTarget'
    },
    {
      value: 'getListInventoryHubToIO',
      label: 'getListInventoryHubToIO'
    },
    {
      value: 'getListInventoryHub',
      label: 'getListInventoryHub'
    },
    {
      value: 'importProductQTYHubToIO',
      label: 'importProductQTYHubToIO'
    },
    // ver 1
    {
      value: 'getListFromHub',
      label: 'getListFromHub'
    },
    {
      value: 'mappingHubToIO',
      label: 'mappingHubToIO'
    },
    {
      value: 'importHubToIO',
      label: 'importHubToIO'
    },

    {
      value: 'getListHubToIO',
      label: 'getListHubToIO'
    },

    {
      value: 'importIOToTarget',
      label: 'importIOToTarget'
    },
    {
      value: 'mappingMetafieldTarget',
      label: 'mappingMetafieldTarget'
    },
    {
      value: 'connectMetafieldTarget',
      label: 'connectMetafieldTarget'
    },
    {
      value: 'addAttributeCustom',
      label: 'addAttributeCustom'
    },
    {
      value: 'getListInventoryHubToTarget',
      label: 'getListInventoryHubToTarget'
    },
    {
      value: 'getDataMetafield',
      label: 'getDataMetafield'
    },
    {
      value: 'importFileTarget',
      label: 'importFileTarget'
    },
    {
      value: 'importProductQTYHubToTarget',
      label: 'importProductQTYHubToTarget'
    },
    {
      value: 'updateProductTarget',
      label: 'updateProductTarget'
    },
    {
      value: 'updateProductTargetQTY',
      label: 'updateProductTargetQTY'
    },
    {
      value: 'connectUpdateProductTarget',
      label: 'connectUpdateProductTarget'
    },
    {
      value: 'connectUpdateProductParentTarget',
      label: 'connectUpdateProductParentTarget'
    },
    {
      value: 'setupVariable',
      label: 'setupVariable'
    },
    {
      value: 'updateProductHubToMain',
      label: 'updateProductHubToMain'
    },
    {
      value: 'mappingIOToTarget',
      label: 'mappingIOToTarget'
    },
    {
      value: 'addAttributeCustom',
      label: 'addAttributeCustom'
    },
    {
      value: 'getListCategoryTarget',
      label: 'getListCategoryTarget'
    },
    {
      value: 'getListIOAsyncTarget',
      label: 'getListIOAsyncTarget'
    },
    {
      value: 'getListProductIOAsyncTarget',
      label: 'getListProductIOAsyncTarget'
    },
    {
      value: 'importHubToTarget',
      label: 'importHubToTarget'
    },
    {
      value: 'getListHubToTarget',
      label: 'getListHubToTarget'
    }
  ]
  listFunctionOrder = [
  {
      value: 'getListOrderTargetToIO',
      label: 'getListOrderTargetToIO'
    },
    {
      value: 'getListOrderTarget',
      label: 'getListOrderTarget'
    },
    {
      value: 'importOrderTargetToIO',
      label: 'importOrderTargetToIO'
    },
    {
      value: 'mappingOrderTargetToIO',
      label: 'mappingOrderTargetToIO'
    },
    {
      value: 'getListOrderIOToHub',
      label: 'getListOrderIOToHub'
    },
    {
      value: 'importOrderIOToHub',
      label: 'importOrderIOToHub'
    },
    {
      value: 'createOrderHub',
      label: 'createOrderHub'
    },
    {
      value: 'getListOrderHubToIO',
      label: 'getListOrderHubToIO'
    },
    {
      value: 'getListOrderHub',
      label: 'getListOrderHub'
    },
    {
      value: 'importOrderHubToIO',
      label: 'importOrderHubToIO'
    },
    {
      value: 'getListOrderIOToTarget',
      label: 'getListOrderIOToTarget'
    },
    {
      value: 'getListOrderShippingRefundCancelIO',
      label: 'getListOrderShippingRefundCancelIO'
    },
    {
      value: 'importOrderIOToTarget',
      label: 'importOrderIOToTarget'
    },
    // End Ver 2
    {
      value: 'importOrderHubToTarget',
      label: 'importOrderHubToTarget'
    },
    {
      value: 'getDataConnectTarget',
      label: 'getDataConnectTarget'
    },
    {
      value: 'getDataConnectHub',
      label: 'getDataConnectHub'
    },
    {
      value: 'getListOrderTargetNewAndImportHub',
      label: 'getListOrderTargetNewAndImportHub'
    },
    {
      value: 'getListOrderHubAndImportTarget',
      label: 'getListOrderHubAndImportTarget'
    },
    {
      value: 'getListOrderTargetNew',
      label: 'getListOrderTargetNew'
    },
    {
      value: 'importOrderTargetToHub',
      label: 'importOrderTargetToHub'
    },
    {
      value: 'createOrUpdateFulfillmentOnHub',
      label: 'createOrUpdateFulfillmentOnHub'
    },
    {
      value: 'getDataOrderExistIO',
      label: 'getDataOrderExistIO'
    },
    {
      value: 'formatOrderTargetToSAAS',
      label: 'formatOrderTargetToSAAS'
    },
    {
      value: 'createOrUpdateFulfillmentOnHub',
      label: 'createOrUpdateFulfillmentOnHub'
    },
    {
      value: 'callAPIOrderExported',
      label: 'callAPIOrderExported'
    },
    {
      value: 'getListOrderItemHubNew',
      label: 'getListOrderItemHubNew'
    },
    {
      value: 'updateFulfillmentOrderTarget',
      label: 'updateFulfillmentOrderTarget'
    },
    {
      value: 'connectUpdateTarget',
      label: 'connectUpdateTarget'
    },
    {
      value: 'updateRefundOrderTarget',
      label: 'updateRefundOrderTarget'
    },
    {
      value: 'findOrderItemId',
      label: 'findOrderItemId'
    },
    {
      value: 'setupVariable',
      label: 'setupVariable'
    },
    {
      value: 'updateOrderShippingStatus',
      label: 'updateOrderShippingStatus'
    },
    {
      value: 'getListOrderItemHubNewAndImportTarget',
      label: 'getListOrderItemHubNewAndImportTarget'
    },
    {
      value: 'refundOrderTarget',
      label: 'refundOrderTarget'
    },
    {
      value: 'cancelOrderTarget',
      label: 'cancelOrderTarget'
    },
    {
      value: 'createOrUpdateFulfillmentTarget',
      label: 'createOrUpdateFulfillmentTarget'
    },
    {
      value: 'getListOrderHubFulfillmentsAndImportTarget',
      label: 'getListOrderHubFulfillmentsAndImportTarget'
    },
    {
      value: 'importOrderHubRefundToTarget',
      label: 'importOrderHubRefundToTarget'
    },
    {
      value: 'importOrderHubShipmentToTarget',
      label: 'importOrderHubShipmentToTarget'
    },
    {
      value: 'getOrderExistHub',
      label: 'getOrderExistHub'
    }
  ]
  currentPage = 1
  totaRows = 0
  perPage = 20
  dialogDetail = false
  dialogForm = false
  isExisted = false
  isTypeProduct = false
  dialogVisible = false
  dialogVisibleAddNew = false
  dataOld = ''
  dataUpdate = ''
  array: any[] = []
  listTitle: any = []
  dataItem = {
    applyTo: [
      {
        _id: ''
      }
    ],
    groupFunction: this.array
  }
  newCondition = {}
  grouped: any
  condition = {
    createdBy: [],
    isTemplate: null
  }
  refForm = ''
  formData = [
    {
      title: '',
      type: '',
      connectionFromType: '',
      connectionToType: '',
      groupFunction: this.array,
      isTemplate: true,
      global: true,
      createdBy: 'Super Admin',
      applyTo: []
    }
  ]
  itemGroup = {
    function: '',
    content: ''
  }
  itemGroupNew = { ...this.itemGroup }
  showFilter = false
  listAccount = [
    {
      account: { _id: '' },
      email: ''
    }
  ]
  moreAccount = {
    _id: '',
    name: ''
  }
  rules = {
    title: [
      {
        required: true,
        message: 'Please input title',
        trigger: 'blur'
      }
    ],
    content: [
      {
        required: true,
        message: 'Please input content',
        trigger: 'blur'
      }
    ],
    type: [
      {
        required: true,
        message: 'Please input type',
        trigger: 'blur'
      }
    ],
    connectionFromType: [
      {
        required: true,
        message: 'Please choose platform',
        trigger: 'blur'
      }
    ],
    connectionToType: [
      {
        required: true,
        message: 'Please choose platform',
        trigger: 'blur'
      }
    ],
    function: [
      {
        required: true,
        message: 'Please choose',
        trigger: 'blur'
      }
    ],
    applyTo: [
      {
        required: true,
        message: 'Please choose',
        trigger: 'blur'
      }
    ],
    isTemplate: [
      {
        required: true,
        message: 'Please choose',
        trigger: 'blur'
      }
    ]
  }
  async created() {
    this.getListAccount()
    this.getListScript(0)
  }
  handleCurrentChange(index = 1) {
    this.currentPage = index
    if (this.showFilter) {
      this.resultQuery(index, this.perPage)
    } else this.getListScript(index)
  }
  handleSizeChange(val: number) {
    this.currentPage = 1
    this.perPage = val
    if (this.showFilter) {
      this.resultQuery(1, this.perPage)
    } else this.getListScript(this.currentPage)
  }
  selectTypeConnect(type: any) {
    if (type === 'product' || type === 'productQTY') {
      this.isTypeProduct = true
    } else this.isTypeProduct = false
  }
  async getListScript(page = 0) {
    await this.$store.dispatch('setLoading', true, { root: true })
    let res: Res = {
      list: [],
      listTitle: [],
      total: 0
    }
    res = await axios.get('user/script-custom', {
      headers: this.headers,
      params: {
        account: localStorage.getItem('account'),
        page: page,
        limit: this.perPage,
        condition:
          this.condition.createdBy.length == 0
            ? this.newCondition
            : this.condition
      }
    })
    if (res) {
      this.listScript = res.list
      this.totaRows = res.total
      res.listTitle.forEach((element: any) => {
        if (!this.listTitle.includes(element.title)) {
          this.listTitle.push(element.title)
        }
      })
    }
    await this.$store.dispatch('setLoading', false, { root: true })
  }
  async getAll(page = 0) {
    await this.$store.dispatch('setLoading', true, { root: true })
    let res: Res = {
      list: [],
      listTitle: [],
      total: 0
    }
    res = await axios.get('user/script-custom', {
      headers: this.headers,
      params: {
        account: localStorage.getItem('account'),
        page: page,
        limit: this.perPage
      }
    })
    if (res) {
      this.listScript = res.list
      this.totaRows = res.total
    }
    await this.$store.dispatch('setLoading', false, { root: true })
  }
  viewDetail(row: any) {
    this.dataOld = JSON.stringify(row)
    this.dataItem = row
    this.dialogDetail = true
    if (row.type === 'product' || row.type === 'productQTY') {
      this.isTypeProduct = true
    } else this.isTypeProduct = false
  }
  clickAdd() {
    this.dialogForm = true
  }
  resetForm() {
    this.refForm = 'formNew'
    const refFrorm = this?.$refs[this.refForm] as HTMLFormElement
    return refFrorm.resetFields()
  }
  resetFormDetail() {
    this.refForm = 'formDetail'
    const refFrorm = this?.$refs[this.refForm] as HTMLFormElement
    return refFrorm.resetFields()
  }
  async clearFilter() {
    this.showFilter = false
    this.condition = {
      createdBy: [],
      isTemplate: null
    }
    this.newCondition = {}
    await this.getAll(0)
  }
  async getListAccount() {
    await this.$store.dispatch('setLoading', true, { root: true })
    let res: Result
    res = await axios.get('admin/user', {
      headers: this.headers,
      params: {
        page: 1,
        limit: 90000
      }
    })
    if (res) {
      this.listAccount = res.data
      this.grouped = this.listAccount.reduce(function (r, a) {
        r[a.email] = r[a.email] || []
        r[a.email].push(a.account)
        return r
      }, Object.create(null))
      this.grouped = Object.entries(this.grouped).map(([k, v]) => ({
        lable: k,
        account: v
      }))
    }
    await this.$store.dispatch('setLoading', false, { root: true })
  }
  async handleAddnew() {
    var nextStep = true
    this.refForm = 'formNew'
    const refFrorm = this?.$refs[this.refForm] as HTMLFormElement
    const isValidate = await refFrorm.validate()
    if (!isValidate) return
    if (
      this.formData[0].connectionFromType === this.formData[0].connectionToType
      && this.formData[0].connectionFromType !== 'magento2'
      && this.formData[0].connectionToType !== 'magento2'
    ) {
      nextStep = false
      return ElMessage.error("Connection from and to can't be identical!!!")
    }
    console.log(this.formData)

    if (nextStep) {
      await this.$store.dispatch('setLoading', true, { root: true })

      await axios
        .post('user/script-custom', this.formData, {
          headers: this.headers,
          params: {
            account: localStorage.getItem('account')
          }
        })
        .then(function (response) {
          if (response.status) {
            ElMessage.success('Create successfully !!!')
          } else {
            ElMessage.error('Something wrong !!!')
          }
        })
        .catch(function (error) {
          ElMessage.error('Something wrong !!!')
          console.log(error)
        })
      await this.getListScript(this.currentPage)
      this.dialogForm = false
      this.formData[0].groupFunction = []
      await this.$store.dispatch('setLoading', false, { root: true })
    }
  }

  async handleSave() {
    this.refForm = 'formDetail'
    const refFrorm = this?.$refs[this.refForm] as HTMLFormElement
    const isValidate = await refFrorm.validate()
    if (!isValidate) return
    this.dataUpdate = JSON.stringify(this.dataItem)
    await this.$store.dispatch('setLoading', true, { root: true })
    await axios
      .post(
        'user/log-custom-history',
        {
          dataOld: this.dataOld,
          dataUpdate: this.dataUpdate
        },
        {
          headers: this.headers,
          params: {
            account: localStorage.getItem('account')
          }
        }
      )
      .then()
      .catch(function (error) {
        console.log(error)
      })
    await axios
      .put('user/script-custom', this.dataItem, {
        headers: this.headers,
        params: {
          account: localStorage.getItem('account')
        }
      })
      .then(function (response) {
        if (response.status) {
          ElMessage.success('Update successfully !!!')
        } else {
          ElMessage.error('Something wrong !!!')
        }
      })
      .catch(function (error) {
        ElMessage.error('Something wrong !!!')
        console.log(error)
      })
    this.dialogDetail = false
    await this.getListScript(this.currentPage)
    this.formData[0].groupFunction = []
    await this.$store.dispatch('setLoading', false, { root: true })
    this.dataUpdate = ''
    this.dataOld = ''
  }

  highlighter(code: any) {
    return highlight(code, languages.js, 'javascript')
  }

  async resultQuery(page = 0, perPage = 20) {
    this.refForm = 'formFilter'
    const refFrorm = this?.$refs[this.refForm] as HTMLFormElement
    const isValidate = await refFrorm.validate()
    if (!isValidate) return
    if (this.condition.createdBy.length == 0) {
      const { createdBy, ...newObj } = this.condition
      this.newCondition = newObj
    }
    await this.$store.dispatch('setLoading', true, { root: true })
    let res: Res = {
      list: [],
      listTitle: [],
      total: 0
    }
    res = await axios.get('user/script-custom', {
      headers: this.headers,
      params: {
        account: localStorage.getItem('account'),
        page: page,
        limit: perPage,
        condition:
          this.condition.createdBy.length == 0
            ? this.newCondition
            : this.condition
      }
    })
    if (res) {
      this.listScript = res.list
      this.totaRows = res.total
    }
    await this.$store.dispatch('setLoading', false, { root: true })
  }
  async clickDelete(id: any) {
    await ElMessageBox.confirm('Do you really want to delete ?', 'Warning', {
      confirmButtonText: 'OK',
      cancelButtonText: 'Cancel',
      type: 'warning'
    })
      .then(() => {
        this.onDelete(id, this.headers)
      })
      .catch((error) => {
        console.log(error)
      })
  }
  async onDelete(id: any, headers: any) {
    await this.$store.dispatch('setLoading', true, { root: true })

    await axios
      .delete('user/script-custom', {
        headers: { headers, id }
      })
      .then(function (response) {
        if (response.status) {
          ElMessage.success('Delete successfully !!!')
        } else {
          ElMessage.error('Something wrong !!!')
        }
      })
      .catch(function (error) {
        console.log(error)
        ElMessage.error('Something wrong !!!')
      })
    await this.$store.dispatch('setLoading', false, { root: true })
    await this.getListScript(this.currentPage)
  }
  removeAccount(array: any, item: any) {
    var index = array.indexOf(item)
    if (index > -1) {
      array.splice(index, 1)
    }
  }
  addAccount(event: any) {
    this.moreAccount = event
  }
  addMoreAccount() {
    this.dataItem.applyTo.forEach((element, index) => {
      if (element._id === this.moreAccount._id) {
        this.isExisted = true
        this.dataItem.applyTo.splice(index, 1)
        return false
      }
    })
    this.dataItem.applyTo = [this.moreAccount, ...this.dataItem.applyTo]
    this.moreAccount = {
      _id: '',
      name: ''
    }
  }
  async addItem() {
    this.refForm = 'formItemGroupNew'
    const refFrorm = this?.$refs[this.refForm] as HTMLFormElement
    const isValidate = await refFrorm.validate()
    if (!isValidate) return
    if (this.dialogDetail) {
      this.dataItem.groupFunction.push(this.itemGroupNew)
    } else {
      this.formData[0].groupFunction.push(this.itemGroupNew)
    }
    this.closeDialog()
  }
  viewDetailFunction(item: any) {
    this.itemGroup = item
    this.dialogVisible = true
  }
  async updateFunctionContent() {
    this.refForm = 'formItemGroupDetail'
    const refFrorm = this?.$refs[this.refForm] as HTMLFormElement
    const isValidate = await refFrorm.validate()
    if (!isValidate) return
    let objIndex = this.dataItem.groupFunction.indexOf(this.itemGroup)
    this.dataItem.groupFunction[objIndex].content = this.itemGroup.content
    this.dialogVisible = false
  }
  async clickDeleteFunction(item: any) {
    await ElMessageBox.confirm('Do you really want to delete ?', 'Warning', {
      confirmButtonText: 'OK',
      cancelButtonText: 'Cancel',
      type: 'warning'
    })
      .then(() => {
        this.formData[0].groupFunction.splice(
          this.formData[0].groupFunction.indexOf(item),
          1
        )
        this.dataItem.groupFunction.splice(
          this.dataItem.groupFunction.indexOf(item),
          1
        )
      })
      .catch((error) => {
        console.log(error)
      })
  }
  closeDialog() {
    this.itemGroup = {
      function: '',
      content: ''
    }
    this.itemGroupNew = { ...this.itemGroup }
    this.dialogVisibleAddNew = false
    this.dialogVisible = false
  }
}
